import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PaymentMethodsComponent } from 'src/app/components/shared/shared-private/payment-methods/payment-methods.component';
import { GeneralService } from 'src/app/core/providers/general.service';
import { CardModule } from 'primeng/card';
import { CreditCard } from 'src/app/core/models/credit-card.model';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { Observable } from 'rxjs';
import { StripeAPIService } from 'src/app/core/providers/private/stripe/stripe-api.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-subscription-modal',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgOptimizedImage,
    TranslateModule,
    DialogModule,
    ButtonModule,
    PaymentMethodsComponent,
    CardModule,
    ProductDetailComponent,
  ],
  templateUrl: './payment-subscription-modal.component.html',
  styleUrls: ['./payment-subscription-modal.component.scss'],
})
export class PaymentSubscriptionModalComponent {
  currentStepItem: number = 0;
  @Input() visible: boolean = false;
  @Input() title: string = '';
  @Output() paymentSelected = new EventEmitter<any>();

  public membership: any;
  public membershipSetup: boolean = false;
  public creditCardList$: Observable<CreditCard[]>;
  public creditCardData: CreditCard[] = [
    {
      id: 'fcvsdu763en8347',
      brand: 'VISA',
      number: 1233,
      expires: '23/12',
      name: 'Adrian Espinoza',
      icon: 'assets/images/visa.svg',
      default: true,
    },
    {
      id: 'dvcubcf443f',
      brand: 'MASTERCARD',
      number: 3633,
      expires: '29/02',
      name: 'Juan Gomez',
      icon: 'assets/images/logo-Mastercard 1.svg',
      default: false,
    },
  ];
  constructor(
    private _generalService: GeneralService,
    private _stripeService: StripeAPIService,
    public _deviceDetectorService: DeviceDetectorService
  ) {
    this._generalService.getCurrentStep().subscribe(step => {
      this.currentStepItem = step;
    });

    this._generalService.membershipSelected$.subscribe(membership => {
      this.membership = membership;
    });
    this._generalService.membershipSetup$.subscribe(membershipSetup => {
      this.membershipSetup = membershipSetup;
    });

    this.creditCardList$ = this._stripeService.creditCardList$;
  }

  ngOnInit(): void {
    if (!environment.production) {
      this._stripeService.creditCardList$.next(this.creditCardData);
    } else {
      this._stripeService.getListCard();
    }
  }

  public previousStep(): void {
    const newStep = this.currentStepItem - 1;
    this._generalService.setCurrentStep(newStep);
  }

  public nextStep(): void {
    const newStep = this.currentStepItem + 1;
    this._generalService.setCurrentStep(newStep);
  }

  upgradeSuccessfull(): void {
    this._generalService.showPaymentSubscription$.next(false);
    this._generalService.showPopupUpgraded$.next(true);
  }

  public onPaymentSelected(paymentSelected: any): void {
    this._generalService.isPaymentSelected$.next(paymentSelected);
  }
  public upgradeMembership(): void {
    this._generalService.isUpgradeClicked$.next(true);
  }

  closePopup() {
    this._generalService.showPaymentSubscription$.next(false);
  }
}
