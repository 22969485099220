import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../../general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  voyagerUuidSession$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  customerIdStripe$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  token$: Observable<string | null> = this.tokenSubject.asObservable();
  codeVerify$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  errorLogin$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(
    private httpClient: HttpClient,
    private _generalService: GeneralService
  ) {}

  setToken(token: string): void {
    this.tokenSubject.next(token);
  }

  clearToken(): void {
    this.tokenSubject.next('');
  }

  signInSocial(provider: string, token: string): Promise<any> {
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/sign-in-oauth`, {
          provider,
          token,
        })
        .subscribe({
          next: (res: any) => {
            if (res.data && res.status < 400) {
              this.customerIdStripe$.next(res.data.customer_id);
              sessionStorage.setItem(
                environment.storeKeys.SESSION,
                JSON.stringify({
                  email: res.data.email,
                  customer: res.data.customer_id,
                  uuid: res.data.uuid,
                })
              );
              sessionStorage.setItem(environment.storeKeys.UUID, res.data.uuid);
              sessionStorage.setItem(
                environment.storeKeys.ACCESS,
                res.data.accessToken
              );
              sessionStorage.setItem(
                'onboarding',
                res.data.isOnboardingRequired
              );
              this.setToken(res.data.accessToken);
              resolve(res.data);
            } else {
              reject(res);
            }
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  signIn(email, password): Promise<any> {
    let loginUser = { email, password };
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/sign-in`, loginUser)
        .subscribe({
          next: (res: any) => {
            if (res.data && res.status < 400) {
              this.customerIdStripe$.next(res.data.customer_id);
              sessionStorage.setItem(
                environment.storeKeys.SESSION,
                JSON.stringify({
                  email: email,
                  customer: res.data.customer_id,
                  uuid: res.data.uuid,
                })
              );
              sessionStorage.setItem(environment.storeKeys.UUID, res.data.uuid);
              sessionStorage.setItem(
                environment.storeKeys.ACCESS,
                res.data.accessToken
              );
              sessionStorage.setItem(
                'onboarding',
                res.data.isOnboardingRequired
              );
              this.setToken(res.data.accessToken);
              resolve(res.data);
            } else {
              reject(res);
            }
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  signUp(email, password): Promise<any> {
    let loginUser = { email, password };
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/sign-up`, loginUser)
        .subscribe({
          next: ({ data }: any) => {
            sessionStorage.setItem(environment.storeKeys.UUID, data.uuid);
            resolve(data);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  verifyAccount(codeEmailVerify): Promise<any> {
    let uuid = sessionStorage.getItem(environment.storeKeys.UUID);
    let codeVerify = {
      uuid: uuid,
      code: codeEmailVerify,
    };
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/verify-account/`, codeVerify)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  verifyResetCode(codeEmailVerify): Promise<any> {
    let email = sessionStorage.getItem(environment.storeKeys.FORGOT);
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/verify-code-password/`, {
          email: email,
          code: codeEmailVerify,
        })
        .subscribe({
          next: (res: any) => {
            if (res.status < 300) {
              sessionStorage.setItem(
                environment.storeKeys.CODE,
                codeEmailVerify
              );
              this.codeVerify$.next(codeEmailVerify);
            }
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  updateAccount(payload: any): Promise<any> {
    const uuid = sessionStorage.getItem(environment.storeKeys.UUID);
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .patch(`${environment.apiUrl}/auth/update-account/${uuid}`, payload)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  forgotPassword(email): Promise<any> {
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/forgot-password/`, { email: email })
        .subscribe({
          next: (res: any) => {
            if (res.status < 300) {
              sessionStorage.setItem(environment.storeKeys.FORGOT, email);
            }
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  resendNewCodeVerification(email: string): Promise<any> {
    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/send-code-verification`, { email })
        .subscribe({
          next: (res: any) => {
            sessionStorage.setItem(environment.storeKeys.FORGOT, email);
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  confirmPassword(password): Promise<any> {
    /* let email = sessionStorage.getItem(environment.storeKeys.FORGOT);
    let code =
      sessionStorage.getItem(environment.storeKeys.CODE) != null
        ? sessionStorage.getItem(environment.storeKeys.CODE)
        : this.codeVerify$.value;
    let newCredentials = {
      email,
      newPassword: password,
      confirmationCode: code,
    };

    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/confirm-password/`, newCredentials)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    }); */
    let email = sessionStorage.getItem(environment.storeKeys.FORGOT);
    let newCredentials = { email, newPassword: password };

    this._generalService.showLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/auth/change-password/`, newCredentials)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }
}
