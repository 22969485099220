<div class="content flex flex-row justify-content-center align-items-center">
  <p-card class="card-payment">
    <ng-template pTemplate="header">
      <img src="assets/images/star_payment.png" alt="star-payment" />
      <h5 class="color-black mb-5 text-center text-lg">
        {{ 'MEMBERSHIP.title_popup' | translate }}
      </h5>
    </ng-template>
    <div class="flex flex-row w-full">
      <div class="price-detail w-full">
        <p class="title">{{ 'MEMBERSHIP.price_detail' | translate }}</p>
        <p class="color-quaternary text-sm">Nomad</p>
        <div class="flex flex-row justify-content-between">
          <p>{{ 'MEMBERSHIP.year_membership' | translate }}</p>
          <p class="color-quaternary">$199</p>
        </div>
        <hr />
        <div class="flex flex-row justify-content-between">
          <p>
            {{ 'ACCOMODATIONS.hotel_confirmation.total_price' | translate }}
          </p>
          <p>$199</p>
        </div>
      </div>
      <!-- <div>
        <app-payment-product-detail
          [membership]="membership"></app-payment-product-detail>
      </div> -->
    </div>

    <div class="room-payment mb-4">
      <div class="w-full">
        <div class="room-payment-target card">
          <app-payment-methods
            [onSelectPaymentMethod]="true"
            [ignoreButtonProced]="true"
            (creditCardSelected)="
              onPaymentSelected($event)
            "></app-payment-methods>
        </div>
      </div>
    </div>

    <div
      class="flex flex-column justify-content-center align-items-center px-2">
      <button
        pButton
        pRipple
        (click)="upgradeSuccessfull()"
        *ngIf="!membershipSetup"
        class="p-button-raised p-button-rounded p-button-warning secondary color-black flex flex-row justify-content-center px-5 py-3 mb-2 proced">
        {{ 'GENERAL.btn_proceed' | translate }}
      </button>

      <button
        pButton
        pRipple
        (click)="upgradeMembership()"
        *ngIf="membershipSetup"
        class="p-button-raised p-button-rounded p-button-warning secondary flex flex-row justify-content-center px-5 py-3 mb-2 proced">
        {{ 'GENERAL.btn_proceed' | translate }}
      </button>
      <!-- <a
        (click)="previousStep()"
        class="link gray text-uppercase"
        *ngIf="!membershipSetup && !_deviceDetectorService.isMobile()">
        BACK
      </a>-->
      <a
        (click)="closePopup()"
        class="link gray text-uppercase"
        *ngIf="!membershipSetup">
        {{ 'GENERAL.btn_close' | translate }}
      </a>
      <div></div>
    </div>
  </p-card>
</div>
