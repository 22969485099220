import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-travellers-inline-mobile',
  standalone: true,
  imports: [CommonModule, InputNumberModule, TranslateModule, FormsModule],
  templateUrl: './travellers-inline-mobile.component.html',
  styleUrls: ['./travellers-inline-mobile.component.scss'],
})
export class TravellersInlineMobileComponent implements OnInit {
  @Output() selectionTravellers: EventEmitter<any> = new EventEmitter();
  private storage = inject(StorageService);
  public adultsInput: number = 0;
  public kidsInput: number = 0;
  public babiesInput: number = 0;
  public travellers: string;

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this.storage.changeStorage$.subscribe(res => {
      if (res) {
        if (res.key == environment.storeKeys.DESTINATION) {
          let destination = JSON.parse(res.value);
          this.adultsInput = destination.adults;
          this.kidsInput = destination.kids;
          this.babiesInput = destination.babies;
        }
      }
    });
  }
  changeTravellers() {
    let textTravellers: string = '';
    if (this.adultsInput > 0) {
      textTravellers =
        this._translateService.instant('SHARED.DESTINATION.adults') +
        ' ' +
        this.adultsInput;
    }
    if (this.kidsInput > 0) {
      textTravellers =
        textTravellers +
        ' ' +
        this._translateService.instant('SHARED.DESTINATION.kids') +
        ' ' +
        this.kidsInput;
    }
    if (this.babiesInput > 0) {
      textTravellers =
        textTravellers +
        ' ' +
        this._translateService.instant('SHARED.DESTINATION.babies') +
        ' ' +
        this.babiesInput;
    }
    this.travellers = textTravellers;
    this.selectionTravellers.emit({
      travellers: this.travellers,
      adults: this.adultsInput,
      kids: this.kidsInput,
      babies: this.babiesInput,
    });
  }
}
