<form [formGroup]="loginForm">
  <div class="field">
    <span class="p-input-icon-right">
      <div class="field">
        <label class="input-label">{{ 'GENERAL.email' | translate }}</label>
        <input formControlName="email" type="text" pInputText required />
      </div>
    </span>
    <div
      *ngIf="f['email'].errors && f['email'].touched"
      class="invalid-feedback">
      <div *ngIf="f['email'].errors['required']">
        {{ 'GENERAL.ERRORS.email_required' | translate }}
      </div>
    </div>
  </div>

  <div class="field">
    <span class="p-input-icon-right">
      <div class="field">
        <label class="input-label">{{ 'GENERAL.pass' | translate }}</label>
        <input formControlName="pass" type="password" pInputText required />
      </div>
    </span>
    <div *ngIf="f['pass'].errors && f['pass'].touched" class="invalid-feedback">
      <div *ngIf="f['pass'].errors['required']">
        {{ 'GENERAL.ERRORS.pass_required' | translate }}
      </div>
    </div>
  </div>
</form>
<div>
  <div *ngIf="error$ | async" class="invalid-feedback mb-4">
    {{ error$ | async }}
  </div>
</div>

<div class="field">
  <button
    [disabled]="loginForm.invalid"
    pButton
    pRipple
    type="button"
    label="{{ 'GENERAL.btn_login' | translate }}"
    class="p-button-rounded p-button-warning secondary color-black flex justify-content-center w-full"
    (click)="goToPrivate()"></button>
</div>

<div class="field mt-3 flex justify-content-center">
  <!-- <div id="google-button"></div> -->
  <button id="google-signin-button" (click)="loginWithGoogle()">
    Login with Google
  </button>
</div>

<div
  class="flex flex-row justify-content-between align-items-center text-options mt-2">
  <div class="flex flex-row align-items-start">
    <p-radioButton
      name="remember"
      value="no"
      inputId="remember"
      [(ngModel)]="remember"
      [ngModelOptions]="{ standalone: true }"></p-radioButton>
    <label for="remember" class="ml-2 font-family-light font-bold">{{
      'GENERAL.remember' | translate
    }}</label>
  </div>
  <a class="color-link font-family-light font-bold" (click)="recoverPass()">{{
    'GENERAL.forgot_pass' | translate
  }}</a>
</div>

<!-- <div class="text-center">
  <p
    *ngIf="!_sidebarLoginSignupService.isSidebar$.value"
    class="text-center mt-5 mb-3 color-light-dark"
  >
    {{ "LOGIN.no_account" | translate
    }}<a
      class="color-light-dark underline"
      (click)="_generalService.onSignup()"
    >
      {{ "GENERAL.btn_sign_up" | translate }}</a
    >
    {{ "LOGIN.login_with" | translate }}
  </p>
  <p
    class="font-family-light font-bold color-light-text mb-3"
    *ngIf="_sidebarLoginSignupService.isSidebar$.value"
  >
    {{ "LOGIN.login_with" | translate }}
  </p>
</div> -->

<!-- <div class="flex flex-row justify-content-center align-items-baseline">
  <img
    src="assets/images/facebook.svg"
    alt="facebook"
    width="30.83px"
    height="30.76px"
    class="m-1"
  />
  <img
    src="assets/images/google.svg"
    alt="facebook"
    width="29px"
    height="29px"
    class="m-1"
  />
</div> -->
