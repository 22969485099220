<p-toast
  [breakpoints]="{
    '920px': { width: '100%', right: '0', left: '0' }
  }"></p-toast>

<div class="payments mt-3" *ngIf="creditCardList$ | async">
  <div
    class="payments-heading flex flex-column align-items-star justify-content-center mb-2">
    <h5 class="preferences-title mt-2 color-link">
      {{
        'ACCOMODATIONS.hotel_confirmation.payment_method_heading' | translate
      }}
    </h5>
  </div>

  <div
    class="flex flex-column align-items-star justify-content-center mt-3"
    *ngIf="!isAdding">
    <div
      class="payments-section mb-3 flex flex-row align-items-center"
      [ngClass]="{
        'justify-content-between': !onSelectPaymentMethod,
        'justify-content-start': onSelectPaymentMethod
      }"
      *ngFor="let item of creditCardList$ | async">
      <div
        [ngClass]="{
          'favorites-check': onSelectPaymentMethod,
          favorites: !onSelectPaymentMethod
        }">
        <i
          class="pi pi-star-fill color-warning"
          *ngIf="!onSelectPaymentMethod && item.default"></i>
        <i
          class="pi pi-star color-warning cursor-default"
          (click)="chooseDefaultPaymentCard(item.id)"
          *ngIf="!onSelectPaymentMethod && !item.default"></i>
        <div *ngIf="onSelectPaymentMethod" class="field-checkbox mt-3 ml-1">
          <p-radioButton
            [inputId]="'2'"
            [value]="item"
            [(ngModel)]="selectedCard"
            [ngModelOptions]="{ standalone: true }"
            (onClick)="setCard($event)"></p-radioButton>
        </div>
      </div>

      <div
        class="description flex flex-row justify-content-start align-items-center">
        <img src="{{ item.icon }}" alt="card" class="mx-2" />
        <h6
          *ngIf="!_deviceDetectorService.isMobile()"
          class="mx-2"
          style="width: 100px">
          {{ item.name }}
        </h6>
        <h6 class="mx-2">**** {{ item.number }}</h6>
        <h6 class="mx-2 color-light-text expires">
          <span *ngIf="!_deviceDetectorService.isMobile()">Expires</span>
          {{ item.expires }}
        </h6>
        <h5
          *ngIf="!_deviceDetectorService.isMobile()"
          class="mx-2 color-dark name">
          {{ item.name }}
        </h5>
      </div>

      <div class="option" *ngIf="!onSelectPaymentMethod">
        <a
          *ngIf="!_deviceDetectorService.isMobile()"
          class="color-danger mt-4 my-0 text-uppercase"
          (click)="deleteCard(item.id)">
          {{ 'GENERAL.btn_delete' | translate }}
        </a>
        <i
          *ngIf="_deviceDetectorService.isMobile()"
          class="fa-solid fa-xmark color-danger"
          (click)="deleteCard(item.id)"></i>
      </div>
    </div>
  </div>

  <div class="mb-1" *ngIf="isAdding">
    <form class="account-form" [formGroup]="creditCardForm">
      <div class="credit-card" id="elementCard"></div>
    </form>
  </div>

  <div
    *ngIf="!isAdding && onSelectPaymentMethod"
    class="flex flex-row justify-content-start align-items-center mb-4">
    <a
      (click)="addNewCreditCard()"
      class="link primary text-uppercase new-card">
      <i class="pi pi-plus"></i>
      Add credit card
    </a>
  </div>

  <button
    *ngIf="!isAdding && !onSelectPaymentMethod"
    pButton
    pRipple
    (click)="addNewCreditCard()"
    class="p-button-raised p-button-rounded p-button-warning secondary color-black flex flex-row justify-content-center px-5 py-3 mb-2">
    {{ 'GENERAL.btn_new_credit_card' | translate }}
  </button>

  <div *ngIf="isAdding" class="add-buttons">
    <button
      pButton
      pRipple
      (click)="confirmCreditCard()"
      class="p-button-raised p-button-rounded p-button-warning secondary color-black flex flex-row justify-content-center px-5 py-3 mb-2 mr-2">
      {{ 'GENERAL.btn_confirm_credit_card' | translate }}
    </button>
    <button
      pButton
      pRipple
      (click)="cancelCreditCard()"
      class="p-button-raised p-button-rounded p-button-danger danger secondary flex flex-row justify-content-center px-5 py-3 mb-2">
      {{ 'GENERAL.btn_cancel' | translate }}
    </button>
  </div>

  <button
    *ngIf="
      !isAdding &&
      onSelectPaymentMethod &&
      !ignoreButtonProced &&
      !_deviceDetectorService.isMobile()
    "
    pButton
    pRipple
    (click)="onProceedToPayment()"
    [disabled]="disabledPay"
    label="{{
      _generalService.isPaymentSelected$.value
        ? ('GENERAL.btn_proceed' | translate)
        : ('GENERAL.btn_select_payment' | translate)
    }}"
    class="p-button-raised p-button-rounded p-button-warning font-family-regular secondary color-black flex flex-row justify-content-center px-5 py-3 mb-2 proced"></button>
</div>
