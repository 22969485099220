import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { QuicklinkDirective, QuicklinkModule } from 'ngx-quicklink';
import { Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SidebarLoginSignupService } from 'src/app/core/providers/public/sidebar-login-signup/sidebar-login-signup.service';
import { GeneralService } from 'src/app/core/providers/general.service';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';
import { VoyagersService } from 'src/app/core/providers/private/voyagers/voyagers.service';
import { SignupService } from 'src/app/core/providers/public/signup/signup.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';
import { BehaviorSubject } from 'rxjs';
declare const google: any;

@Component({
  selector: 'app-content-login',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    TranslateModule,
    RadioButtonModule,
    QuicklinkDirective,
    RouterLink,
    QuicklinkModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './content-login.component.html',
  styleUrls: ['./content-login.component.scss'],
})
export class ContentLoginComponent implements OnInit {
  private storage = inject(StorageService);
  public loginForm: FormGroup = new FormGroup({});
  public remember = '';
  public error$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private formBuilder: FormBuilder,
    public _sidebarLoginSignupService: SidebarLoginSignupService,
    public _generalService: GeneralService,
    private _authService: AuthService,
    private _voyagersService: VoyagersService,
    private router: Router,
    private _translateService: TranslateService,
    private _signupService: SignupService,
    private cdr: ChangeDetectorRef
  ) {
    this.error$ = this._authService.errorLogin$;
  }

  ngOnInit(): void {
    this.createForm();

    if (google.accounts) {
      // google.accounts.id.initialize({
      //   client_id: environment.YOUR_GOOGLE_CLIENT_ID,
      //   callback: this.handleCredentialGoogleResponse.bind(this),
      // });
      google.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' } // Opciones de estilo del botón
      );
      if (!environment.production) {
        google.accounts.id.prompt(); // Mostrar el botón si el usuario no ha iniciado sesión
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      pass: ['', Validators.required],
      remember: [''],
    });
  }

  goToPrivate(): void {
    const email = this.loginForm.controls['email'].value;
    const pass = this.loginForm.controls['pass'].value;

    if (this.loginForm.status == 'VALID') {
      this._authService
        .signIn(email, pass)
        .then(res => {
          this._sidebarLoginSignupService.showSidebar$.next(false);
          this.cdr.detectChanges();
          if (res.status == 401) {
            this._authService.errorLogin$.next(
              this._translateService.instant('GENERAL.ERRORS.verify_email')
            );

            this.storage.set(environment.storeKeys.UUID, res.data.uuid);
            this.storage.set(environment.storeKeys.FORGOT, email);
            this._generalService.showSignup$.next(true);
            this._signupService.showCheckYourInbox$.next(true);
          } else if (res.status == 400) {
            this._authService.errorLogin$.next(res.message);
          } else {
            this._authService.errorLogin$.next(null);
            this._voyagersService.voyagerEmailSession$.next(email);
            this._generalService.showLogin$.next(false);
            this._signupService.showCheckYourInbox$.next(false);

            if (this._generalService.clickMoreInfo$.value) {
              this._generalService.clickMoreInfo$.next(false);
              this.router.navigate(['/accomodation']);
            } else {
              this.router.navigate(['/dashboard']);
              return;

              let onboarding = JSON.parse(
                this.storage.get(environment.storeKeys.ONBOARDING)
              );
              if (onboarding) {
                this.router.navigate(['/onboarding']);
              } else {
                this.router.navigate(['/dashboard']);
              }
            }
          }
        })
        .catch(e => {
          this._sidebarLoginSignupService.showSidebar$.next(true);
          this._authService.errorLogin$.next(e.message);
          console.log(e);
        });
    }
  }

  recoverPass(): void {
    this._sidebarLoginSignupService.showSidebar$.next(false);
    this._generalService.showRecoverPass$.next(true);
  }

  handleCredentialGoogleResponse(response: any) {
    console.log('response', response);
    this._authService.signInSocial('google', response.credential);
  }

  loginWithGoogle() {
    const clientId = environment.CLIENT_ID_COGNITO;
    const redirectUri = environment.redirectUri;
    const domain = environment.domainCognito;
    const state = this.generateState(); // Genera un valor seguro para el estado
    const scope = 'openid profile email';
    const identityProvider = 'Google';
    this.storage.set(environment.storeKeys.STATE, state);

    const url = `https://${domain}/oauth2/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&identity_provider=${identityProvider}&scope=${encodeURIComponent(
      scope
    )}&state=${state}`;

    window.location.href = url;
  }

  generateState(length: number = 32): string {
    // Conjunto de caracteres permitidos en el estado, incluyendo letras y números
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ''; // Cadena que contendrá el valor generado

    // Bucle para generar cada carácter aleatorio hasta alcanzar la longitud deseada
    for (let i = 0; i < length; i++) {
      // Genera un índice aleatorio para seleccionar un carácter de la cadena 'characters'
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex); // Añade el carácter aleatorio a 'result'
    }

    return result; // Devuelve el valor de 'state' generado
  }
}
