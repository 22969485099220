import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { QuicklinkDirective, QuicklinkModule } from 'ngx-quicklink';
import { RouterLink } from '@angular/router';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';
import { MessageService } from 'primeng/api';
import { GeneralService } from 'src/app/core/providers/general.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';
import { SignupService } from 'src/app/core/providers/public/signup/signup.service';

@Component({
  selector: 'app-check-your-inbox',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    CardModule,
    TranslateModule,
    ButtonModule,
    FormsModule,
    QuicklinkDirective,
    QuicklinkModule,
    RouterLink,
  ],
  templateUrl: './check-your-inbox.component.html',
  styleUrls: ['./check-your-inbox.component.scss'],
})
export class CheckYourInboxComponent {
  private storage = inject(StorageService);
  public code: string = '';

  constructor(
    private _authService: AuthService,
    private _messageService: MessageService,
    private _translateService: TranslateService,
    public _generalService: GeneralService,
    private _signupService: SignupService
  ) {}

  onContinue(): void {
    // this._authService
    //   .verifyAccount(this.code)
    //   .then((res: string) => {
    //     this._authService.voyagerUuidSession$.next(res);
    //     this._generalService.showSignup$.next(false);
    //     // this._signupService.showCompleteData$.next(true);
    //   })
    //   .catch(e => {
    //     console.log(e.message);
    //   });

    this._generalService.showSignup$.next(false);
    this._signupService.showCheckYourInbox$.next(false);
  }

  async sendNewCode(): Promise<any> {
    const email = await this.storage.get(environment.storeKeys.FORGOT);

    this._authService
      .resendNewCodeVerification(email)
      .then(res => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: res.message,
        });
      })
      .catch(e => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: this._translateService.instant('GENERAL.ERRORS.no_send_code'),
        });
      });
  }
}
