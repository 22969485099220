import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { DestinationService } from 'src/app/core/providers/public/destination/destination.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { GeneralService } from 'src/app/core/providers/general.service';
import { LastSearchDestinationComponent } from '../destination-form/last-search-destination/last-search-destination.component';
import { FilterDestinationsComponent } from '../destination-form/filter-destinations/filter-destinations.component';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { CalendarInlineMobileComponent } from '../destination-form/calendar-inline-mobile/calendar-inline-mobile.component';
import { TravellersInlineMobileComponent } from '../destination-form/travellers-inline-mobile/travellers-inline-mobile.component';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';
import { FlowService } from 'src/app/core/providers/trip/flow.service';
import { Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CitiesSearch } from 'src/app/core/models/cities.model';

@Component({
  selector: 'app-popup-destination-mobile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    TranslateModule,
    CardModule,
    AutoCompleteModule,
    LastSearchDestinationComponent,
    FilterDestinationsComponent,
    CalendarModule,
    FormsModule,
    CalendarInlineMobileComponent,
    TravellersInlineMobileComponent,
  ],
  templateUrl: './popup-destination-mobile.component.html',
  styleUrls: ['./popup-destination-mobile.component.scss'],
  providers: [],
})
export class PopupDestinationMobileComponent implements OnInit {
  @ViewChild('dateCalendar') dateCalendar: Calendar;
  private storage = inject(StorageService);
  private searchTerm = new Subject<string>();
  public rangeDates: any;
  public travellers: any;
  public adults: any;
  public kids: any;
  public babies: any;
  public page: number = 0;
  public minDate: Date = new Date();
  public destinyIsSelected: boolean = false;
  public datesIsSelected: boolean = false;
  public travellersIsSelected: boolean = false;
  public destiny: string = '';
  public showResults: boolean = false;
  public notFound: boolean = false;

  constructor(
    private _destinationService: DestinationService,
    private _generalService: GeneralService,
    private _flowService: FlowService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public _deviceDetectorService: DeviceDetectorService
  ) {}
  ngOnInit(): void {
    this.storage.changeStorage$.subscribe(res => {
      if (res) {
        if (res.key == environment.storeKeys.DESTINATION) {
          let destination = JSON.parse(res.value);
          this.destiny =
            destination.destiny.name != null
              ? destination.destiny.name
              : destination.destiny.region;
          this.travellers = destination.travellers;
          let ranges = [];
          destination.rangeDates.forEach(date => {
            ranges.push(new Date(date));
          });
          this.rangeDates = ranges;
        }
      }
    });

    this.searchTerm
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term: string) => {
          if (term.trim().length === 0) {
            return of({ data: [] });
          }
          return this._destinationService.getDestination(term).pipe(
            catchError(() => {
              return of({ data: [] });
            })
          );
        }),
        tap(() => (this.showResults = false))
      )
      .subscribe((res: any) => {
        if (res.data.length === 0) {
          this.notFound = true;
        } else {
          this.showResults = true;
          this.notFound = false;
        }

        this._destinationService.zoneSearchs$.next(
          (res.data as Array<CitiesSearch>).filter(x => x.name == null)
        );
        this._destinationService.citiesSearchs$.next(
          (res.data as Array<CitiesSearch>).filter(x => x.name != null)
        );
      });
  }

  // selectResult(destiny: any) {
  //   const selectedItem = destiny?.name ? destiny?.name : destiny?.region;
  //   this.destiny = selectedItem;
  //   this.destinyObject = destiny;
  //   this.showResults = false;
  // }

  closeResults() {
    this.showResults = false;
  }

  filterDestination(word): void {
    this.searchTerm.next(word.target.value);
  }

  cancel() {
    this._generalService.showPopupDestination$.next(false);
  }

  goToNext() {
    if (!this.destinyIsSelected && this.destiny != null) {
      this.destinyIsSelected = true;
      this.cdr.detectChanges();
    } else if (!this.datesIsSelected && this.rangeDates != null) {
      this.datesIsSelected = true;
    }
  }

  goToSearch() {
    let destiny = JSON.parse(
      this.storage.get(environment.storeKeys.SELECTED_DESTINY)
    );
    let destination = JSON.stringify({
      destiny: destiny,
      rangeDates: this.rangeDates,
      travellers: this.travellers,
      adults: this.adults,
      kids: this.kids,
      babies: this.babies,
    });
    this.storage.set(environment.storeKeys.DESTINATION, destination);
    this._generalService.showPopupDestination$.next(false);
    this._deviceDetectorService.isMobile()
      ? this._flowService.showDestinationSearchPanelMobile$.next(true)
      : this._flowService.showDestinationSearchPanelMobile$.next(false);

    this._flowService
      .getHotelsFiltered(JSON.parse(destination))
      .then(res => {
        if (res != null) {
          window.location.reload();
          this.router.navigate(['/trip']);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  // DESTINY ---------------------------------------------

  destinySelectedEvent(event): void {
    this.storage.set(
      environment.storeKeys.SELECTED_DESTINY,
      JSON.stringify(event)
    );
    this.destiny = event?.name ? event?.name : event?.region;
    this.destinyIsSelected = true;
  }

  focusSelectionDestiny(event: any): void {
    this.searchTerm.next(event.target.value);
    this.destinyIsSelected = false;
    this.destiny = event.target.value;
  }

  // DATES -----------------------------------------------

  datesSelectedEvent(event): void {
    this.rangeDates = event;
    this.datesIsSelected = true;
  }

  focusSelectionDates(): void {
    this.datesIsSelected = false;
  }

  // TRAVELLERS  -----------------------------------------

  travellersSelectedEvent(event): void {
    this.travellers = event.travellers;
    this.adults = event.adults;
    this.kids = event.kids;
    this.babies = event.babies;
    this.travellersIsSelected = true;
  }

  focusSelectionTravellers(): void {
    this.travellersIsSelected = false;
  }
}
