import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { GeneralService } from '../general.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Amenities,
  HotelDetails,
  RequestHotelDetails,
} from '../../models/hotel-details.model';
import { formatDate } from '@angular/common';
import { ProgressListStepper } from '../../models/progress-list.model';
import { ReservationResponse } from '../../models/reservation-confirm.model';
import { StorageAdapter } from '../infrastructure/adapters/storage-adapter';
import { BookingConfirmRequest } from '../../models/booking-confirm.model';
import { Destination } from '../../models/destination.model';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  idSelectedHotel$: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  titlePopupViewPrice$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  filterFavorites$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showInfoHotel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showGoogleMap$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  rangePriceSlider$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    []
  );
  minPriceSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  maxPriceSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(3000);
  rangeSavingSlider$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    []
  );
  minSavingSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  maxSavingSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(3000);
  rangeDatesSelected$: BehaviorSubject<Array<Date>> = new BehaviorSubject<
    Array<Date>
  >(null);
  tabSelectedRooms$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  endOfRooms$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  enabledReserveRooms$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showDestinationSearchPanelMobile$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  showHistoricMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  selectionTabProfileMobile$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  totalPriceSelectRooms$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  numberOfRommsSelected$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  hotelSelected$: BehaviorSubject<HotelDetails> =
    new BehaviorSubject<HotelDetails>(null);
  updateHotels$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  trips$: BehaviorSubject<HotelDetails[]> = new BehaviorSubject<HotelDetails[]>(
    []
  );
  filteredTrips$: BehaviorSubject<HotelDetails[]> = new BehaviorSubject<
    HotelDetails[]
  >([]);
  cardBooking$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  progressListStepper$: BehaviorSubject<ProgressListStepper> =
    new BehaviorSubject<ProgressListStepper>({
      destiny: null,
      accomodation: null,
      rooms: null,
      payment: null,
    });

  reservationConfirm$: BehaviorSubject<ReservationResponse> =
    new BehaviorSubject<ReservationResponse>(null);
  rangeFreeSlider$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    []
  );
  isEdit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookingConfirm$: BehaviorSubject<BookingConfirmRequest> =
    new BehaviorSubject<BookingConfirmRequest>(null);
  currencySelected$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  numberOfNigths$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  roomLevelOne$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  roomLevelTwo$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  roomLevelThree$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  roomLevelFour$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  roomLevelFive$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  roomLevelSix$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  thereAreDestination$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  iconFavorite$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  activitingTab$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showPopupMembershipInPayment$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor(
    private _translateService: TranslateService,
    public _generalService: GeneralService,
    private httpClient: HttpClient,
    private storage: StorageAdapter,
    private router: Router,
    private _messageService: MessageService,
    private _deviceDetectorService: DeviceDetectorService
  ) {
    this.filteredTrips$.subscribe(res => {
      this.thereAreDestination$.next(res.length > 0 ? true : false);
    });
  }

  onViewPrice(info: string): void {
    this._generalService.showPopupViewPrice$.next(true);
    this._generalService.showSignup$.next(false);
    this._generalService.showLogin$.next(false);
    this.titlePopupViewPrice$.next(
      info == 'price'
        ? this._translateService.instant('TRIP.popup_view_price.title')
        : this._translateService.instant('TRIP.popup_view_price.reserve')
    );
  }

  getHotelsFiltered(filters: Destination): Promise<any> {
    this._generalService.showLoading$.next(true);
    let composefilters = null;
    const uuid = sessionStorage.getItem(environment.storeKeys.UUID);
    if (filters) {
      let start = formatDate(filters.rangeDates[0], 'dd/MM/yyyy', 'en-US');
      let end = formatDate(filters.rangeDates[1], 'dd/MM/yyyy', 'en-US');

      composefilters = uuid
        ? `uuid=${uuid}&destiny=${filters.destiny.id}&startDate=${start}&endDate=${end}&adults=${filters.adults}&kids=${filters.kids}&babies=${filters.babies}`
        : `destiny=${filters.destiny.id}&startDate=${start}&endDate=${end}&adults=${filters.adults}&kids=${filters.kids}&babies=${filters.babies}`;
    }
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/booking/list?${composefilters}`)
        .subscribe({
          next: (res: RequestHotelDetails) => {
            if (res != null) {
              let noEmptyTrips: any[] = [];
              if (res.data.length > 0) {
                noEmptyTrips = (res.data as HotelDetails[]).filter(
                  x =>
                    x != null &&
                    x.accomodation_name != null &&
                    x.accomodation_name != '' &&
                    x.description != null &&
                    x.description.length > 0
                );
              } else if (
                res.data.length == 0 &&
                this._deviceDetectorService.isMobile()
              ) {
                this._messageService.add({
                  severity: 'info',
                  summary: 'Info',
                  detail: this._translateService.instant(
                    'ACCOMODATIONS.no_records.title'
                  ),
                });
              } else {
                res.data;
              }
              this.filteredTrips$.next(Object.assign([], noEmptyTrips));
            } else {
              this.filteredTrips$.next(Object.assign([], []));
            }
            this._generalService.showLoading$.next(false);
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  //TODO: Este método todavía no se usa
  getHotelDetails(hotelId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(`${environment.apiUrl}/booking/accomodation-detail?id=${hotelId}`)
        .subscribe({
          next: (res: any) => {
            this.hotelSelected$.next(res);
            resolve(res);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => console.info('complete'),
        });
    });
  }

  getHotelSelectedDetails(): HotelDetails {
    return JSON.parse(this.storage.get(environment.storeKeys.HOTEL_SELECTED));
  }

  bookingConfirm(): Promise<ReservationResponse> {
    if (!this.bookingConfirm$.value) {
      this.bookingConfirm$.next(
        this.storage.get(environment.storeKeys.BOOKING_CONFIRM)
      );
    }
    let payload = {
      reservation: {
        checkIn: this.bookingConfirm$.value.reservation.checkIn,
        checkOut: this.bookingConfirm$.value.reservation.checkOut,
        cardId: this.bookingConfirm$.value.reservation.cardId,
        arrivalTime: this.bookingConfirm$.value.reservation.arrivalTime,
        checkoutEstimated:
          this.bookingConfirm$.value.reservation.checkoutEstimated,
        reasonTrip: this.bookingConfirm$.value.reservation.reasonTrip,
        selectedRooms: [],
        occupancies: this.bookingConfirm$.value.reservation.occupancies,
        currency: this.bookingConfirm$.value.reservation.currency,
      },
      mainGuestIncluded: this.bookingConfirm$.value.mainGuestIncluded,
      mainGuest: this.bookingConfirm$.value.mainGuest,
      specialRequest: this.bookingConfirm$.value.specialRequest,
    };
    this.bookingConfirm$.value.reservation.selectedRooms.forEach(room => {
      payload.reservation.selectedRooms.push({
        rateKey: room.rateKey,
        price: room.price,
      });
    });

    return new Promise((resolve, reject) => {
      this.httpClient
        .post(`${environment.apiUrl}/booking/confirm`, payload)
        .subscribe({
          next: (res: ReservationResponse) => {
            this.reservationConfirm$.next(res);
            this.storage.set(
              environment.storeKeys.RESERVATION_CONFIRM,
              JSON.stringify(res)
            );
            resolve(res);
          },
          error: e => {
            reject(e);
            console.error(e);
          },
          complete: () => console.info('complete'),
        });
    });
  }

  cancelReservation(bookingId: number): Promise<any> {
    this._generalService.showLoading$.next(true);
    let uuid = this.storage.get(environment.storeKeys.UUID);
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(`${environment.apiUrl}/booking/cancel/${uuid}/${bookingId}`)
        .subscribe({
          next: res => {
            resolve(res);
          },
          error: e => {
            this._generalService.showLoading$.next(false);
            reject(e);
            console.error(e);
          },
          complete: () => {
            this._generalService.showLoading$.next(false);
          },
        });
    });
  }

  getRoomsSelected(): any {
    this.numberOfRommsSelected$.next(0);
    this.bookingConfirm$.next({
      reservation: null,
      mainGuestIncluded: false,
      mainGuest: null,
      specialRequest: null,
      totalPriceReservation: 0,
    });
    let total = 0;
    let occupancies = JSON.parse(
      this.storage.get(environment.storeKeys.DESTINATION)
    );
    this.bookingConfirm$.value.reservation = {
      hotelId: this.hotelSelected$.value.id.toString(),
      checkIn: '',
      checkOut: '',
      cardId: '',
      arrivalTime: '',
      checkoutEstimated: '',
      reasonTrip: 0,
      selectedRooms: [],
      occupancies: {
        adults: occupancies.adults,
        children: occupancies.kids,
        babies: occupancies.babies,
      },
      currency: this.hotelSelected$.value.currency,
    };
    this.hotelSelected$.value.rooms.forEach(room => {
      room.rates.forEach(rate => {
        if (rate.numberRoomsSelected > 0) {
          this.numberOfRommsSelected$.next(
            this.numberOfRommsSelected$.value + rate.numberRoomsSelected
          );

          this.bookingConfirm$.value.reservation.selectedRooms.push({
            rateKey: rate.rateKey,
            price: rate.prices.price,
            numberOfRooms: rate.numberRoomsSelected,
            nameOfRoom: room.name,
            taxes: rate.fees,
          });
          total = total + rate.prices.price * rate.numberRoomsSelected;
        }
      });
    });
    this.bookingConfirm$.value.totalPriceReservation = total;
    this.storage.set(
      environment.storeKeys.BOOKING_CONFIRM,
      JSON.stringify(this.bookingConfirm$.value)
    );
    return this.bookingConfirm$.value;
  }

  gropuRooms(trip: HotelDetails) {
    const objIds = trip.rooms.reduce((a, { id, name, rates }) => {
      a[id] = a[id] || { id, name, rates: [] };
      return {
        ...a,
        ...{ [id]: { id, name, rates: a[id].rates.concat(rates) } },
      };
    }, {});
    this.hotelSelected$.next({ ...trip, rooms: Object.values(objIds) });
    this.storage.set(
      environment.storeKeys.HOTEL_SELECTED,
      JSON.stringify(this.hotelSelected$.value)
    );
  }

  getNigthsDestination(): number {
    let destination = this.storage.get(environment.storeKeys.DESTINATION);
    let destinationObj = JSON.parse(destination);
    if (destinationObj) {
      if (destinationObj.rangeDates) {
        destinationObj.rangeDates[0] = new Date(destinationObj.rangeDates[0]);
        destinationObj.rangeDates[1] = new Date(destinationObj.rangeDates[1]);
        return Math.floor(
          (Date.UTC(
            destinationObj.rangeDates[1].getFullYear(),
            destinationObj.rangeDates[1].getMonth(),
            destinationObj.rangeDates[1].getDate()
          ) -
            Date.UTC(
              destinationObj.rangeDates[0].getFullYear(),
              destinationObj.rangeDates[0].getMonth(),
              destinationObj.rangeDates[0].getDate()
            )) /
            (1000 * 60 * 60 * 24)
        );
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getTotalPrice(): number {
    let roomsSelected = this.getRoomsSelected();
    let nigths = this.getNigthsDestination();
    this.numberOfNigths$.next(nigths);
    let total = 0;
    roomsSelected.reservation.selectedRooms.forEach(room => {
      total += Number(room.price) * room.numberOfRooms;
    });
    return total;
  }

  groupAmanities(list): any[] {
    try {
      let lang = this.storage.get(environment.storeKeys.USER_LANGUAGE);
      if (lang != 'es' && lang != 'en') lang = 'en';
      let groupedAmanities = list.reduce((groups, item: Amenities) => {
        const val = item.type;
        groups[val] = groups[val] || [];
        groups[val].push(item.texts.filter(x => x.language == lang)[0].text);
        return groups;
      }, {});

      let mappedAmanities = Object.keys(groupedAmanities).map(key => ({
        type: key,
        value: groupedAmanities[key],
      }));
      return mappedAmanities;
    } catch (error) {
      return null;
    }
  }

  updateBookingConfirmation(): void {
    this.storage.set(
      environment.storeKeys.BOOKING_CONFIRM,
      JSON.stringify(this.bookingConfirm$.value)
    );
  }

  lastSearchGoToAccomodations(
    startDate: Date,
    endDate: Date,
    adults: number,
    babies: number,
    kids: number,
    destinyId: string,
    country: string,
    city: string,
    region: string
  ): void {
    let textTravellers: string = '';
    if (adults > 0) {
      textTravellers =
        this._translateService.instant('SHARED.DESTINATION.adults') +
        ' ' +
        adults;
    }
    if (kids > 0) {
      textTravellers =
        textTravellers +
        ' ' +
        this._translateService.instant('SHARED.DESTINATION.kids') +
        ' ' +
        kids;
    }
    if (babies > 0) {
      textTravellers =
        textTravellers +
        ' ' +
        this._translateService.instant('SHARED.DESTINATION.babies') +
        ' ' +
        babies;
    }
    let destination: Destination = {
      rangeDates: [startDate, endDate],
      destiny: {
        country: country,
        id: destinyId,
        name: city,
        region: region,
      },
      adults: adults,
      babies: babies,
      kids: kids,
      travellers: textTravellers,
    };

    this.storage.set(
      environment.storeKeys.DESTINATION,
      JSON.stringify(destination)
    );

    this.getHotelsFiltered(destination).then(_ => {
      this.router.navigate(['/trip']);
      this._generalService.showPopupDestination$.next(false);
    });
  }
}
