import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';
import { PublicNavBarComponent } from '../../../shared-public/public-navbar/public-nav-bar.component';
import { GeneralService } from 'src/app/core/providers/general.service';

@Component({
  selector: 'app-set-new-pass',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    TranslateModule,
    ReactiveFormsModule,
    PublicNavBarComponent,
  ],
  templateUrl: './set-new-pass.component.html',
  styleUrls: ['./set-new-pass.component.scss'],
})
export class SetNewPassComponent {
  public newPassForm: FormGroup = new FormGroup({});
  public passConfirm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthService,
    private router: Router,
    private _generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newPassForm.controls;
  }

  createForm(): void {
    this.newPassForm = this.formBuilder.group({
      pass: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.* )(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
          ),
        ],
      ],
      rePass: ['', Validators.required],
    });

    this.newPassForm.setValidators([this.passConfirmationValidator]);
  }

  passConfirmationValidator = (form: FormGroup) => {
    const pass = form.get('pass').value;
    const rePass = form.get('rePass').value;

    return pass === rePass ? null : { passConfirm: 'pass confirm mismatch' };
  };

  onChange(event) {
    if (
      this.newPassForm.controls['pass'].value ==
      this.newPassForm.controls['rePass'].value
    ) {
      this.passConfirm = true;
    } else {
      this.passConfirm = false;
    }
  }

  onUpdate(): void {
    this._authService
      .confirmPassword(this.newPassForm.controls['pass'].value)
      .then(res => {
        this._generalService.showSetNewPass$.next(false);
        this.router.navigate(['/recoverpass/confirmpass']);
      })
      .catch(e => {
        console.log(e);
      });
  }
}
