import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/core/providers/private/dashboard/dashboard.service';
import { LastSearch } from 'src/app/core/models/dashboard-metricts.model';
import { BehaviorSubject } from 'rxjs';
import { StorageAdapter } from 'src/app/core/providers/infrastructure/adapters/storage-adapter';
import { environment } from 'src/environments/environment';
import { FlowService } from 'src/app/core/providers/trip/flow.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-last-search-destination',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './last-search-destination.component.html',
  styleUrls: ['./last-search-destination.component.scss'],
})
export class LastSearchDestinationComponent implements OnInit {
  private storage = inject(StorageAdapter);
  public lastSerachs: LastSearch[] = [];
  public locale$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private _dashboardService: DashboardService,
    private _translateService: TranslateService,
    private _flowService: FlowService,
    private _deviceDetectrorService: DeviceDetectorService
  ) {
    this._translateService.onLangChange.subscribe(res => {
      this.locale$.next(res.lang);
    });
  }

  ngOnInit(): void {
    this.locale$.next(this.storage.get(environment.storeKeys.USER_LANGUAGE));

    if (this._deviceDetectrorService.isMobile()) {
      this._dashboardService
        .getMetrics()
        .then(res => {
          this.lastSerachs = res.last_search;
        })
        .catch();
    }
  }

  goToAccomodations(lastSearch: LastSearch): void {
    this._flowService.lastSearchGoToAccomodations(
      new Date(lastSearch.startDate),
      new Date(lastSearch.endDate),
      Number(lastSearch.adults),
      Number(lastSearch.babies),
      Number(lastSearch.kids),
      lastSearch.destiny,
      lastSearch.country_name,
      lastSearch.city_name,
      lastSearch.region_name
    );
  }
}
