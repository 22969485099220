import { Component, Output, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { QuicklinkDirective, QuicklinkModule } from 'ngx-quicklink';
import { RouterLink } from '@angular/router';
import { AuthService } from 'src/app/core/providers/private/auth/auth.service';
import { GeneralService } from 'src/app/core/providers/general.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/providers/infrastructure/storage.service';

@Component({
  selector: 'app-check-inbox',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    CardModule,
    TranslateModule,
    ButtonModule,
    FormsModule,
    QuicklinkDirective,
    QuicklinkModule,
    RouterLink,
  ],
  templateUrl: './check-inbox.component.html',
  styleUrls: ['./check-inbox.component.scss'],
})
export class CheckInboxComponent {
  @Output() toastEmitter: EventEmitter<any> = new EventEmitter();
  private storage = inject(StorageService);
  public code: string = '';

  constructor(
    private _authService: AuthService,
    private _generalService: GeneralService
  ) {}

  onContinue(): void {
    /* const email = this.storage.get(environment.storeKeys.FORGOT); */
    this._generalService.showRecoverPass$.next(false);
    this._authService
      .verifyResetCode(this.code)
      .then((res: string) => {
        this._generalService.showSetNewPass$.next(true);
      })
      .catch(e => {
        console.log(e.message);
      });
  }

  sendNewCode(): void {
    const email = this.storage.get(environment.storeKeys.FORGOT);

    this._authService
      .forgotPassword(email)
      .then(res => {
        this.toastEmitter.emit(res);
      })
      .catch(e => {
        console.log(e);
      });
  }
}
